.jobs-hero-section {
    background: linear-gradient(45deg, #121C26, #206DC5);
    /* min-height: 100%; */
    padding: 140px 80px;
    min-height: 100%;
}

.job-right-sec {
    /* margin: auto; */
    display: flex;
    justify-content: end;
}

.jobs-hiring-banner {
    padding: 2px 20px 3px;
    background: #F2F4F7;
    width: 175px;
    margin-top: 25px;
    margin-bottom: 18px;
    border-radius: 16px;
    font-size: 0.82rem;
    display: flex;
    gap: 7px;
    justify-content: start;
    align-items: center;
    color: #101828;
}

.jobs-hero-buttons {
    margin-top: 4rem;
}

.subHeading-text {
    font-size: 2.6rem;
    line-height: 3.1rem;
}

.learn-more-btn {
    margin-right: 18px;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-decoration: none;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 17px;
    font-weight: bold;
    background: transparent;
    position: relative;
    transition: all 1s;
    overflow: hidden;
    z-index: 1;
}

.learn-more-btn:hover {
    color: white;
}

.learn-more-btn::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0;
    left: -40px;
    transform: skewX(45deg);
    background-color: #0673f0;

    z-index: -1;
    transition: all 1s;
}

.learn-more-btn:hover::before {
    width: 160%;
}

.upload-cv-button {
    border: none;
    color: #fff;
    background-image: linear-gradient(30deg, #0673f0, #558bca);
    border-radius: 6px;
    background-size: 100% auto;
    font-family: inherit;
    font-size: 17px;
    padding: 12px 20px;
    font-weight: bold;
}

.upload-cv-button-disable {
    border: none;
    color: #fff;
    background: gray;
    border-radius: 6px;
    background-size: 100% auto;
    font-family: inherit;
    font-size: 17px;
    padding: 12px 20px;
    font-weight: bold;
    cursor: no-drop;
}

.upload-cv-button:hover {
    background-position: right center;
    background-size: 200% auto;
    -webkit-animation: pulse 2s infinite;
    animation: pulse512 1.5s infinite;
}

@keyframes pulse512 {
    0% {
        box-shadow: 0 0 0 0 #05bada66;
    }

    70% {
        box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
    }

    100% {
        box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
    }
}

@media (max-width: 768px) {
    .upload-cv-button.jobsPageHeroSec {
        margin-top: 14px
    }

    .job-right-sec {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

}
@media (max-width:1024px){
    .job-right-sec {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
}

@media (max-width: 494px) {
    .job-left-sec {
        padding-top: 90px;
    }

    .jobs-hero-section {
        padding: 0 30px;
    }

    .jobs-hero-buttons {
        margin-top: 60px;
    }

    .subHeading-text {
        font-size: 2.2rem;
        line-height: 2.7rem;
    }
}