.publicLanding-secondSec-header{
    color: #206DC5;
    font-weight: 600;
    text-align: center;
    font-size: 1.2rem;
}
.publicLanding-secondSec-subHeader{
    text-align: center;
    font-size: .9rem;

}
.yellow-text{
    color: #F79009;
}
.publicLanding-secondSec-secSubHeader{
    font-weight: 600;
    font-size: 30px;
}

.mainLandingSecondSecImg{
    margin-left: auto;
    margin-right: auto;
}

.mainLandingSectionCenterAlign{
    text-align: center;
}