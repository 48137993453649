.publicLanding-thirdSec-header {
  color: #206DC5;
  font-weight: 600;
  text-align: center;
  font-size: 1.2rem;
}

.publicLanding-thirdSec-subHeader {
  text-align: center;
  font-size: .9rem;
}

.yellow-text {
  color: #F79009;
}

.publicLanding-thirdSec-secSubHeader {
  font-weight: 600;
  font-size: 30px;
}

.thirdComponentMainContainer {
  margin-left: auto;
  margin-right: auto;
  background: var(--Gray-50, #F9FAFB);
}

.midSpace1 {
  margin-bottom: 50px;
}

.midSpace2 {
  margin-bottom: 20px;
}

.btnCol_thirdSec {
  text-align: center;
}

.btnHeading {
  /* font-size: 36px; */
  color: #206DC5;
  font-weight: 600;
}

.icon-container {
  cursor: pointer;
}

.currActive .icon-container {
  background-image: linear-gradient(to right, #103662, #206DC5);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transition: all ease-in-out 0.2s;
}

.currActive p {
  color: #0553ab;
  transition: all ease-in-out 0.2s;
}

.currActive .icon-container svg path {
  fill: #D4E7FD;
  stroke: #D4E7FD;
  transition: all ease-in-out 0.2s;
}

.btnTxt {
  color: #081B31;
  font-weight: 600;
  cursor: pointer;
}

.currActive {
  border-bottom: 2px solid #206DC5;
  transition: all ease-in-out 0.2s;
}

.mbSection_three_btn {
  display: none;
}

@media screen and (max-width: 769px) {
  .dtSection_three_btn {
    display: none;
  }

  .mbSection_three_btn {
    display: block;
    margin-bottom: 24px;
  }

  .btnMobileText {
    color: #081B31;
    font-weight: 600;
    cursor: pointer;
    margin-top: 5px;
  }

  .currActive .btnMobileText {
    color: #0553ab;
    transition: all ease-in-out 0.2s;
  }

  .currActive {
    border-bottom: 2px solid #206DC5;
    transition: all ease-in-out 0.2s;
  }

  .currActive .icon-container svg path {
    fill: #D4E7FD;
    stroke: #D4E7FD;
    transition: all ease-in-out 0.2s;
  }

  .currActive .icon-container {
    background-image: linear-gradient(to right, #103662, #206DC5);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    transition: all ease-in-out 0.2s;
  }
}

.video-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  width: 80% !important;
 
}

.video-container video {
  transition: opacity 2s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
.hirePPLandingPageBoxShadow-video{
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 16px;
}
@media (min-width: 769px) {
  .video-container {
    width: 80%;
  }

  .dtSection_three_btn {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .video-container {
    width: 100%;
    height: max-content;
    border-radius: 10px;
    margin-top: 24px!important;
    top: 10%;

  }
}

.centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.centered-content h3,
.centered-content p {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.centered-content.fade-in h3,
.centered-content.fade-in p {
  opacity: 1;
}
