@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-notification, .ant-message {
  font-family: Inter, sans-serif;
  font-size: 0.95rem;
}

/* global button styles */
.btn-white {
  @apply font-semibold bg-[#FCFCFD] border border-[#D0D5DD] rounded-lg shadow-sm text-[#475467] hover:border-blue-300;
}
.btn-disabled {
  @apply font-semibold bg-gray-200 border border-[#D0D5DD] rounded-lg shadow-sm text-[#344054] cursor-not-allowed;
}
.btn-blue {
  @apply font-semibold bg-[#206DC5] border border-[#206DC5] text-[#FCFCFD] rounded-lg shadow-sm hover:brightness-[1.12] transition-all duration-200 active:bg-[#28476d];
}
.btn-grey-disable {
  @apply font-medium bg-gray-200 border  text-gray-500 rounded-lg shadow-sm cursor-not-allowed;
}
.btn-gradient-blue {
  @apply border border-[#206DC5] font-semibold bg-gradient-to-r from-[#103662] to-[#206DC5] text-white rounded-lg shadow-sm hover:brightness-[1.2] transition-all duration-200;
}
.btn-red {
  @apply font-semibold bg-[#D92D20] border border-[#D92D20] text-[#FCFCFD] rounded-lg shadow-sm;
}
.btn-green {
  @apply font-semibold bg-[#12B76A] border border-[#12B76A] text-[#FCFCFD] rounded-lg shadow-sm;
}
