.data-hero-section {
  background: linear-gradient(45deg, #103662 0%, #206DC5 100%);
  padding: 120px .9rem 50px;
  color: #fff;
  text-align: center;
}

.data-content, .imprint-para-main-container {
  text-align: center;
  margin-top: 20px;
}

.data-banner, .imprint-para, .legal-notice {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.data-main-heading, .imprint-section-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.data-sub-heading, .imprint-sub-heading, .affiliate-sub-heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.imprint-para-main-container {
  padding-top: 40px;
}

.imprint-definition-list, .imprint-bullet-list {
  list-style: disc inside;
  padding-left: 0;
  margin-left: 20px;
}

.imprint-definition-item, .imprint-bullet-item {
  margin-bottom: 10px;
}

.imprint-term, .data-main-heading {
  font-weight: bold;
}

.imprint-term {
  display: inline;
}

.imprint-definition {
  margin-left: 5px;
  display: inline;
}

.imprint-sub-section {
  margin-bottom: 20px;
}
