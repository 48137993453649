.thirdComponentMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-row {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-image {
    width: 45%;
    /* Smaller size for the image */
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    /* Darker shadow for hover-like effect */
}

.button-row {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.apply-button {
    display: flex;
    width: 229px;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 8px;
    border: 1px solid var(--gradient-primary-80060045-deg, #103662);
    background: var(--gradient-primary-80060045-deg, linear-gradient(45deg, #103662 0%, #206DC5 100%));
    /* Shadow/xs-down */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.apply-button:hover {
    background: var(--gradient-primary-80060045-deg, linear-gradient(45deg, #0a2a4b 0%, #1b5b9b 100%));
}

.fourthComponentMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background: var(--Gray-50, #F9FAFB);
}


.fourth_icon {
    width: 50px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.heading {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.description {
    font-size: 0.9rem;
    color: #475467;
}
.fifthComponentMainContainer {
    margin-top:50px;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background: var(--Gray-50, #F9FAFB);
}

.main-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.sub-heading {
    font-size: 1rem;
    color: #475467;
    text-align: center;
    margin-bottom: 40px;
}

.fifthheading {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;

}

.fifthdescription {
    font-size: 0.9rem;
    color: #475467;

}

.ifthBottomIcon{margin-top: 24px;}


