.terms-hero-section {
    background-color: #f0f4f8;
    padding: 50px 0;
    text-align: center;
}

.terms-banner,
.terms-main-heading,
.terms-sub-heading,
.terms-para,
.terms-intro-paragraph,
.bold-text,
.empTermsSecOnePara,
.terms-section-heading,
.terms-subsection-heading,
.privacy-table-section {
    /* color: white; */
    font-size: 1rem;
}

.terms-banner {
    color: white;
}

.terms-main-heading {
    font-size: 2.5rem;
    font-weight: bold;
    /* margin: 20px 0; */
}

.terms-sub-heading {
    font-size: 1.2rem;
    margin: 20px 0;
}

.termsParaMainContainer {
    background-color: #fff;
    margin-bottom: 40px;
    /* padding: 20px 0; */
}

.terms-para {
    color: #444;
}

.terms-intro-paragraph {
    text-align: justify;
}

.bold-text {
    font-weight: 700;
}

.empTermsSecOnePara {
    margin-top: 20px;
}

.terms-section-heading,
.terms-subsection-heading {
    font-weight: bold;
    margin-top: 20px;
}

.terms-section-heading {
    font-size: 24px;
}

.terms-subsection-heading {
    font-size: 15px;
}

.privacy-table-section {
    margin: auto;
    max-width: 1200px;
    padding: 16px;
    margin-top: 25px;
}

.terms-para ul {
    list-style-type: disc; /* Adds bullet points */
    margin-left: 20px; /* Indents the list */
    padding-left: 20px;
}

.terms-para ul li {
    margin-bottom: 10px; /* Adds space between list items */
}
