.about-hero-section {
  padding-top: 150px;
  padding-left: 125px;
  padding-right: 125px;
  padding-bottom: 130px;
  background: linear-gradient(45deg, #121C26, #206DC5);
  min-height: 100%;
}
.about-team-section {
  padding: 0 125px;
}
.about-career-section {
  padding: 50px 125px;
}
.about-last-section {
  padding: 55px 125px;
}

.quoted-text {
  position: relative;
  display: inline-block;
  padding: 15px 30px;
}

.quoted-text::before,
.quoted-text::after {
  content: "";
  position: absolute;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  background-size: contain;
  background-repeat: no-repeat;
}

.quoted-text::before {
  background-image: url("./../../assets/symbol/opening-quotes.svg");
  left: -25px;
  top: 0px;
}

.quoted-text::after {
  background-image: url("./../../assets/symbol/closing-quotes.svg");
  right: -25px; /* Adjust position as needed */
  bottom: 0px;
}

@media (max-width: 768px) {
  .about-hero-section {
    padding: 120px 40px;
    padding-bottom: 80px;
  }
  .about-team-section {
    padding: 0 40px;
  }
  .about-career-section {
    padding: 50px 40px;
  }
  .about-last-section {
    padding: 50px 40px;
  }
  .quoted-text::before,
  .quoted-text::after {
    width: 30px;
    height: 30px;
  }
  .quoted-text::before{
    left: -10px;
  }
  .quoted-text::after{
    right: -10px;
  }
}
