.grid-container .img-container {
  display: none;
}

@media (min-width: 1150px) {
  .grid-container .img-container {
    display: block;
  }
}

@media (max-width: 1150px) {
    .grid-container > div {
        max-width: 680px;
        margin: 0 auto;
    }
}

