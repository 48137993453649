/* Ensure all columns (except the first) have the same width */
.pricing-table th,
.pricing-table td {
  min-width: 150px; /* Adjust as needed */
}

.pricing-table .ant-table-cell:nth-child(2),
.pricing-table .ant-table-cell:nth-child(3),
.pricing-table .ant-table-cell:nth-child(4),
.pricing-table .ant-table-cell:nth-child(5) {
  width: 200px !important; /* Adjust as needed */
}

/* Ensure the overview column has a larger width */
.pricing-table .ant-table-cell:first-child {
  width: 250px !important; /* Adjust as needed */
}

/* Add bottom margin to table 2 and top margin to table 3 */
.pricing-table-table2 {
  margin-bottom: 30px;
}

.pricing-table-table3 {
  margin-top: 30px;
}

/* Fix the top and bottom border for premium cells in table 1 and table 2 */
.premium-cell, .premium-cell-ihx {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  text-align: center !important;
  align-items: center;
  justify-content: center;
}

.premium-header-cell, .premium-header-cell-ihx {
  border-top: 1px solid black !important;
  border-radius: 10px 10px 0 0 !important;
}

.premium-cell-bottom, .premium-cell-bottom-ihx {
  border-bottom: 1px solid black !important;
  border-radius: 0 0 10px 10px !important;
}

/* Fix the top and bottom border for premium cells in table 3 */
.premium-cell-table-3 {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  text-align: center !important;
  align-items: center;
  justify-content: center;
}

.premium-header-cell-table-3 {
  border-top: 1px solid black !important;
  border-radius: 10px 10px 0 0 !important;
}

.premium-cell-table-3-bottom {
  border-bottom: 1px solid black !important;
  border-radius: 0 0 10px 10px !important;
}

.ant-table-thead > tr > .ant-table-cell {
  vertical-align: middle;
}

.ant-table-cell.premium-cell.plan-detail-cell,
.ant-table-cell.premium-cell-ihx.plan-detail-cell,
.ant-table-cell.premium-cell-table-3.plan-detail-cell {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  display: block !important;
  text-align: left !important;
}

.plan-detail-cell img {
  display: inline !important;
  margin-left: 8px !important;
  vertical-align: middle !important;
}

.plan-detail-cell {
  padding: 0 16px !important;
}

/* Adjust plan detail cell styling */
.plan-detail-cell strong {
  display: block;
  font-size: 18px;
  margin-bottom: 8px;
}

.plan-detail-cell p {
  margin: 0;
  font-size: 14px;
}

/* Preserve the bottom border on hover */
.premium-cell-bottom:hover,
.premium-cell-bottom-ihx:hover,
.premium-cell-table-3-bottom:hover,
.last-row-fourth-cell:hover {
  border-bottom: 1px solid black !important;
}

/* Adjust the column width */
.ant-table-cell {
  width: auto;
  white-space: normal;
}

.ant-table-thead > tr > th {
  white-space: nowrap;
}

.check-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.premium-header-cell .premium-cell,
.premium-header-cell-ihx .premium-cell-ihx,
.premium-cell-table-3 .premium-cell-table-3 {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  display: flex;
}

.ant-table-tbody > tr:last-child:hover > td.premium-cell-bottom,
.ant-table-tbody > tr:last-child:hover > td.premium-cell-bottom-ihx,
.ant-table-tbody > tr:last-child:hover > td.premium-cell-table-3-bottom,
.ant-table-tbody > tr:last-child:hover > td.last-row-fourth-cell {
  border-bottom: 1px solid black !important;
}

.popular-badge{
  margin-left: 12px;
}
/* Custom styles for the Mobile Carousel */
.hirePPLandingPageBoxShadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.priceCardTitle {
  margin-top: 20px;
}

/* Adjustments for better mobile experience */
.ant-carousel .slick-dots li button {
  background: #ffffff;
}

/* Custom styles for the Mobile Carousel */
.hirePPLandingPageBoxShadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.priceCardTitle {
  margin-top: 20px;
}

/* Adjustments for better mobile experience */
.ant-carousel .slick-dots {
  bottom: -15px; /* Pushes the indicator further down */
}

.ant-carousel .slick-dots li button {
  background: #ffffff;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #000000;
}

.ant-btn-circle {
  background-color: #ffffff;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ant-btn-circle:hover {
  background-color: #f0f0f0;
}

.ant-btn-circle {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-circle svg {
  width: 20px;
  height: 20px;
}

/* Ensuring no overlap between buttons and cards */
.relative.bg-gray-100 {
  background-color: #f9fbfe;
}

.relative.bg-gray-100 .ant-carousel .slick-slide {
  padding: 0 10px; /* Adjust padding to reduce horizontal scroll */
  display: flex;
  justify-content: center; /* Center the card */
}

.relative.bg-gray-100 .ant-carousel .slick-list {
  overflow: hidden;
}

/* Adjust the card size for mobile view */
.relative.bg-gray-100 .hirePPLandingPageBoxShadow {
  max-width: 300px; /* Set a maximum width for the cards */
  width: 100%; /* Ensure cards take full width available */
}

/* Adjust the container for the buttons */
.relative.bg-gray-100 .ant-carousel .slick-prev,
.relative.bg-gray-100 .ant-carousel .slick-next {
  top: 50%;
  transform: translateY(-50%);
}

.relative.bg-gray-100 .ant-carousel .slick-prev {
  left: -50px;
}

.relative.bg-gray-100 .ant-carousel .slick-next {
  right: -50px;
}

.radioBtnCssPrice{
  border-radius: 12px;
}


