.hero-section {
  background-image: linear-gradient(45deg, #121C26, #206DC5);
  color: white;
  padding: 155px 7.5rem;
  min-height: 100%;
  /* min-height: 90vh; */
}

.hero-right-sec {
  justify-content: center;
  align-items: end;
}

.hiring-banner {
  padding: 3px 8px;
  background: #F2F4F7;
  margin-top: 25px;
  margin-bottom: 18px;
  border-radius: 16px;
  font-size: 0.82rem;
  font-weight: 500;
  display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
  color: #101828;
}

@media (max-width: 767px) {
  .hero-section {
    padding: 120px 25px 50px;
  }

  .hero-right-sec {
    justify-content: center;
    align-items: center;
  }

  .hiring-banner {
    font-size: 0.75rem;
    margin-bottom: 12px;
    margin-top: 0;
  }
}

@media (min-width: 768px) and (max-width:1023px) {
  .hero-section {
    padding: 120px 50px 50px;
  }

  .hero-right-sec {
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
}

.video-container {
  position: relative;
  overflow: hidden;
}

.video-poster {
  position: relative;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16/9;
  /* Maintain aspect ratio */
}

.play-button {
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play-button::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 20px solid white;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  margin-left: 5px;
}

.fade-in {
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}