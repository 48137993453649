.terms-hero-section {
    background: linear-gradient(45deg, #103662 0%, #206DC5 100%);
    padding: 40px 20px;
    text-align: center;
    color: white;
    width: 100%;
}

.terms-content {
    max-width: 800px;
    margin: 0 auto;
}

.terms-banner {
    margin-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.dot-icon {
    margin-right: 8px;
}

.terms-main-heading {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 20px;
}

.terms-sub-heading {
    font-size: 20px;
    margin-bottom: 20px;
}

.terms-intro-paragraph {
    font-size: 15px;
    margin-top: 20px;
}

.terms-para {
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
}

/* Responsive styling */
@media (max-width: 768px) {
    .terms-hero-section {
        padding: 40px 10px;
    }

    .main-heading {
        font-size: 1.5rem;
    }

    .sub-heading {
        font-size: 1rem;
    }

    .intro-paragraph {
        font-size: 0.875rem;
    }

    .terms-banner {
        margin-top: 35px;
    }
}

.termsParaMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.privacy-second-section,
.privacy-table-section {
    margin: auto;
    max-width: 1200px;
    padding: 16px;
    margin-top: 25px;
}

.privacy-second-section h2,
.privacy-second-section h3,
.privacy-table-section h2,
.privacy-table-section h3 {
    color: #1A202C;
}

.privacy-second-section p,
.privacy-table-section p {
    color: #4A5568;
    line-height: 1.6;
}

.privacy-second-section .ant-table-thead>tr>th {
    background: #EDF2F7;
    font-weight: 600;
}

.privacy-second-section .ant-table-tbody>tr>td {
    background: #F7FAFC;
}

@media (max-width: 768px) {
    .privacy-second-section,
    .privacy-table-section {
        padding: 8px;
    }

    .privacy-second-section h2,
    .privacy-table-section h2 {
        font-size: 1.5rem;
    }

    .privacy-second-section h3,
    .privacy-table-section h3 {
        font-size: 1.25rem;
    }

    .privacy-second-section p,
    .privacy-table-section p {
        font-size: 0.875rem;
    }
}

.privacy-table-section .list-disc {
    margin-left: 1.25rem;
}

.privacy-table-section .list-disc li {
    margin-bottom: 0.5rem;
}

.privacy-fifth-section {
    margin: 20px auto;
    max-width: 800px;
}

.privacy-fifth-section h2,
.privacy-fifth-section h3 {
    color: #333;
}

.privacy-fifth-section p {
    color: #666;
}

.privacy-fifth-section ul {
    margin-top: 10px;
}

.privacy-fifth-section li {
    color: #444;
}
