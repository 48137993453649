.carousel-container-fifthSec {
  display: none;
}

@media (min-width: 450px) and (max-width: 768px) {
  .publicLanding-fifthSec-card {
    display: none !important;
  }
  .carousel-container-fifthSec {
    display: block;
  }
 
}


@media (max-width: 450px) {
  .publicLanding-fifthSec-card {
    display: none !important;
  }
  .fifth-sec-img{
    display: none !important;
  }

  .carousel-container-fifthSec {
    display: block;
  }
 
}
