.sixth-sec{
    width: 90%;
    background: #F2F8FE;
    
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.sixth-sec-btn{
    background: linear-gradient(45deg, #103662, #206DC5);
    outline: none;
    border-radius: 6px;
    width: 200px;
    color: white;
    font-weight: 450;
    padding: 6px 8px;
}
.sixth-sec-btn-disable{
    background: gray;
    outline: none;
    border-radius: 6px;
    width: 200px;
    color: white;
    font-weight: 450;
    padding: 6px 8px;
    cursor: no-drop;
}