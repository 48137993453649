.contactLandingPage-submit-btn{
    background: linear-gradient(45deg, #103662, #206DC5);
    color: white;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    font-weight: 450;
}
.contactLandingPage-submit-btn-disable{
    background: gray;
    color: rgb(254, 252, 252);
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    font-weight: 450;
    cursor: no-drop;
}
.contact-second-sec-btn{
    padding: 10px 12px;
    background: linear-gradient(45deg, #103662, #206DC5);
    border-radius: 6px;
    color: white;
    width: 100%;
}