.cookie-data-para {
    /* Ensure specific styling for the cookie policy */
}

.cookie-data-section-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.cookie-data-definition-list {
    list-style: disc inside;
    margin-left: 20px;
    margin-bottom: 20px;
}

.cookie-data-definition-item {
    margin-bottom: 10px;
}

.cookie-data-term {
    font-weight: bold;
}

.cookie-data-definition {
    margin-left: 10px;
}

.cookie-data-sub-section-heading {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
}

.cookie-data-sub-section-description {
    margin-bottom: 20px;
}

.cookie-data-sub-definition-list {
    list-style: disc inside;
    margin-left: 20px;
    margin-bottom: 20px;
}

.cookie-data-sub-definition-item {
    margin-bottom: 10px;
}

.cookie-data-sub-term {
    font-weight: bold;
}

.cookie-data-sub-details {
    margin-left: 20px;
}

.cookie-data-links-list {
    list-style: disc inside;
    padding-left: 20px;
}

.cookie-data-links-item {
    margin-bottom: 10px;
}
