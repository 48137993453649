.data-hero-section {
    background: linear-gradient(45deg, #103662 0%, #206DC5 100%);
    padding: 40px 120px;
    text-align: center;
    color: white!important;
    width: 100%;
}

.data-content {
    margin-top: 60px;
    text-align: center;
}

.data-banner,
.data-main-heading,
.data-sub-heading,
.data-section-heading,
.data-term,
.data-term-number {
    color: black;
    font-weight: bold;
}

.data-hero-section .data-section-heading{
    color: white!important;
}

.data-banner,
.data-para {
    font-size: 1rem;
}

.data-main-heading {
    font-size: 2.5rem;
}

.data-sub-heading {
    font-size: 1.2rem;
    margin: 20px 0;
}

.data-para {
    color: #444;
    padding: 20px;
    margin: 0 auto;
    max-width: 80%;
    text-align: justify;
}

@media (max-width: 768px) {
    .data-para {
        width: 95%;
    }
}

.data-intro-paragraph,
.data-definition,
.data-definition-item {
    text-align: justify;
}

.bold-text {
    font-weight: 700;
}

.data-section-heading {
    font-size: 24px;
    margin-top: 20px;
    text-align: left;
}

.data-term-number,
.data-definition {
    font-size: 16px;
}

.data-term {
    font-size: 18px;
    margin-top: 15px;
}

.data-definition {
    margin-top: 5px;
    color: #444;
}

.data-definition-list {
    list-style: none;
    padding: 0;
}

.data-definition-item {
    margin-bottom: 10px;
    padding-left: 1.5em;
    position: relative;
}

.data-definition-item::before {
    content: "•";
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
    line-height: 1;
    color: #444;
}

.data-bullets {
    padding-left: 1rem;
    list-style-type: disc;
}

.data-term {
    margin-top: 1rem;
}

.data-definition-list {
    padding-left: 0;
}

.data-hero-section .data-banner,
.data-hero-section .data-main-heading,
.data-hero-section .data-sub-heading,
.data-hero-section .data-section-heading,
.data-hero-section .data-term,
.data-hero-section .data-term-number {
    color: white;
    font-weight: bold;
}

/* Tailwind CSS class for responsive table */
.overflow-x-auto {
    overflow-x: auto;
}

.w-full {
    width: 100%;
}

.min-w-full {
    min-width: 100%;
}

/* Ensures table does not overflow and is scrollable */
.data-sub-processors-table {
    overflow-x: auto;
    width: 100%;
}

@media (max-width: 768px) {
    .data-sub-processors-table {
        overflow-x: auto;
    }
    .data-definition-item{
        overflow-x: scroll;
        text-align: left;
    }
}
