.publicLanding-sixthSection-header {
    color: #206DC5;
    font-weight: 600;
    text-align: center;
    font-size: 1.2rem;
}

.publicLanding-sixthSection-subHeader {
    text-align: center;
    font-size: .9rem;

}

.yellow-text {
    color: #F79009;
}

.publicLanding-sixthSection-secSubHeader {
    font-weight: 600;
    font-size: 30px;
}

.sixthSecCards {
    display: flex;
    padding: 16px 0px 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.sixthSecCards:hover {
    cursor: pointer;
}

.sixthSecCards h1 {
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
}

.sixthCardCol.activeCard {
    border-left: 4px solid var(--Primary-500, #2888F6);
}

.sixthCardCol {
    margin-top: 16px;
    border-left: 4px solid var(--Gray-100, #F2F4F7);
}

.sixthMainRow {
    overflow: hidden;
}
.sixth-img-container {
    position: relative;
    height: 100%;
   
}

.sixthSecImgContainerimg {
    position: absolute;
    /* min-width: 779px; */
    /* min-height: ; */
    height: 440px;
    min-width: 150%;
    /* min-width: 550px; */
    /* margin-top: 16px; */
}
.sixthSecMobileImg{
    position: absolute;
    width: 155px;
    right: -30%;  
    top: 120px; 
}

.sixthSecImgContainerimgMB {
    display: none;
}
.sixthSecMobileImgMB{
    display: none;
}

@media screen and (max-width: 920px) {
    .sixthSecImgContainerimg {
        display: none;
    }
    .sixthSecMobileImg{
        display: none;
    }
    .sixthSecImgContainerimgMB {
        display: block;
        margin-bottom: 24px;
        height: 250px;
        width: 100%;
    }
    .sixthSecMobileImgMB {
        display: block;
        position: absolute;
        width: 80px;
        bottom: 20px;
        right: 20px;
    }
}
@media (max-width: 769px) {
    .sixthSecImgContainerimgMB{
        width: 100%;
        height: 100%;
    }
    .sixthSecMobileImgMB {
        right: 10%;
        /* height: ; */
        bottom: 10%;
        /* top: 5%; */
    }
}