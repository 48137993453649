.access-landing-page {
  position: relative;
  min-height: 100vh;
  max-width: 1920px;
  margin: auto;
}
.access-landing-page::before {
  content: "";
  background-image: url("../../assets/image/access/Dot grid.png");
  background-repeat: no-repeat;
  background-position: -7.2em -6.7em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  -webkit-mask-image: linear-gradient(
    to bottom right,
    black 0%,
    transparent 60%
  );
  mask-image: linear-gradient(to bottom right, black 0%, transparent 60%);
}

.first-product-hero {
  background-image: url("../../assets/image/access/product1-bg.svg");
  height: 170px;
  /* background-size: 270px; */
  background-repeat: no-repeat;
  background-position: -12px -8px;
  position: relative;
}
.first-product--icon {
  position: absolute;
  top: 70px;
  left: 103px;
}
.first-product--name {
  position: absolute;
  top: 68%;
  left: 29%;
}
.second-product {
  background-image: url("../../assets/image/access/product2-bg.png");
  background-repeat: no-repeat;
  background-position: 98% 0px;
}
.third-product {
  background-image: url("../../assets/image/access/product3-bg.png");
  background-repeat: no-repeat;
  background-position: 98% 0px;
}
@media (max-width: 768px) {
  .access-landing-page::before {
    background-image: none;
  }
}




