.ai-main-section {
  min-height: 100%;
}

.main-box {
  box-shadow: rgba(3, 84, 189, 0.24) 0px 3px 8px;
  border-radius: 8px;
}
.download-btn {
  background-color: #254a6f;
  color: white;
  padding: 6px 14px;
  border-radius: 6px;
}
.btn-main {
  background-color: #254a6f;
  color: white;
  padding: 6px 14px;
  border-radius: 6px;
}

/* .ant-tree-treenode .ant-tree-title {
  background: #eaf3fe;
} */

.parent-title {
  font-weight: 700;
  color: #206dc5;
  font-size: 1.2rem;
}

.child-title {
  font-weight: 500;
  color: black;
  /* background:#EAF3FE; */
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid rgb(230, 230, 230);
}

.child-value {
  /* font-weight:500; */
  display: inline-block;
  color: black;
  /* background: #98A2B3    ; */
  /* border-radius: 4px; */
  padding: 0 5px;
  /* line-height: 28px; */
  margin: 2px 0;
  background: #eaf3fe;
  border: 1px solid #eaf3fe;
  /* border: 2px solid #EAF3FE; */
  /* font-size: .85rem; */

  border-radius: 5px;
  /* background:#EAF3FE; */
}

.pdf-container {
  height: 600px;
  width: 100%;
}
.json-container {
  height: 600px;
}
.download-mobile-btn {
  display: none;
  background-color: #254a6f;
  color: white;
  padding: 6px 14px;
  border-radius: 6px;
}
.json-container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.json-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* Ensure the content is still scrollable */
.json-container {
  /* overflow: hidden; Prevent scrollbar from appearing */
  overflow: -moz-scrollbars-none; /* Old Firefox */
  -ms-overflow-style: none; /* Internet Explorer */
}
@media (max-width: 1068px) {
  /* .ai-main-section {
    padding: 120px 20px;
  } */
}
.pdf-box {
  height: 600px;
}
@media (max-width: 1023px) {
  .ai-main-section {
    padding: 0 0 !important;
  }

  .pdf-container {
    height: 1100px;
  }
  .json-container {
    margin-top: 30px;
    height: 700px;
  }
  .pdf-box {
    height: 450px;
  }
  .download-mobile-btn {
    display: block;
  }
  .ant-tabs-extra-content {
    display: none;
  }
}
.ai-parser-hero-section {
  padding: 150px 90px 80px;
  background: linear-gradient(45deg, #121c26, #206dc5);
  min-height: 100%;
}
@media (max-width: 768px) {
  .ai-parser-hero-section {
    padding: 125px 30px;
    min-height: 100%;
  }
}

.contact-sales {
  border: none;
  color: #fff;
  background-image: linear-gradient(30deg, #0673f0, #558bca);
  border-radius: 6px;
  background-size: 100% auto;
  font-family: inherit;
  font-size: 17px;
  padding: 12px 20px;
  font-weight: bold;
}

.contact-sales-disable {
  border: none;
  color: #fff;
  background: gray;
  border-radius: 6px;
  background-size: 100% auto;
  font-family: inherit;
  font-size: 17px;
  padding: 12px 20px;
  font-weight: bold;
  cursor: no-drop;
}

.contact-sales:hover {
  background-position: right center;
  background-size: 200% auto;
  -webkit-animation: pulse 2s infinite;
  animation: pulse512 1.5s infinite;
}

@keyframes pulse512 {
  0% {
    box-shadow: 0 0 0 0 #05bada66;
  }

  70% {
    box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
  }
}

@media (max-width: 768px) {
  .contact-sales.jobsPageHeroSec {
    margin-top: 14px;
  }
}

.live-Trial-btn {
  border: none;
  color: #000;
  background-color: white;
  border-radius: 6px;
  background-size: 100% auto;
  font-family: inherit;
  font-size: 17px;
  padding: 12px 20px;
  font-weight: bold;
  margin-right: 16px;
}

.live-Trial-btn-disable {
  border: none;
  color: #fff;
  background: gray;
  border-radius: 6px;
  background-size: 100% auto;
  font-family: inherit;
  font-size: 17px;
  padding: 12px 20px;
  font-weight: bold;
  cursor: no-drop;
}

.live-Trial-btn:hover {
  background-position: right center;
  background-size: 200% auto;
  -webkit-animation: pulse 2s infinite;
  animation: pulse512 1.5s infinite;
}

@keyframes pulse512 {
  0% {
    box-shadow: 0 0 0 0 #05bada66;
  }

  70% {
    box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
  }
}

.aiParser-hero-buttons {
  margin-top: 2rem;
}

.ai-parser-sub-heading {
  color: #206dc5;
  font-weight: 600;
  text-align: center;
  font-size: 1.2rem;
}

.ai-parser-main-heading {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .ai-parser-main-heading {
    font-size: 30px;
  }
}

.ai-parser-description {
  text-align: center;
  font-size: 0.9rem;
  color: rgb(71 84 103 / var(--tw-text-opacity));
}

.use-cases-section {
  text-align: center;
  padding: 50px 0px 0px;
}
.first-cases-section {
  text-align: center;
  padding: 0px;
}

.use-cases-sub-heading {
  color: #206dc5;
  font-weight: 600;
  text-align: center;
  font-size: 1.2rem;
}

.use-cases-main-heading {
  font-size: 36px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .use-cases-main-heading {
    font-size: 30px;
  }
}

.use-cases-description {
  font-size: 1rem;
  color: rgb(71 84 103 / var(--tw-text-opacity));
  margin-bottom: 40px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.use-cases-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.use-case-card {
  position: relative;
  width: calc(35% - 20px);
  overflow: hidden;
  border-radius: 8px;
}

.use-case-card img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
  text-align: left;
}

@media (max-width: 768px) {
  .use-case-card {
    width: calc(100% - 20px);
  }
}

.intro-text p {
  font-size: 0.9rem;
  color: rgb(71 84 103 / var(--tw-text-opacity));
  margin-bottom: 10px;
  text-align: center;
}

.intro-text {
  width: 80%;
  margin: auto;
}
.parserImgTxt {
  font-size: 0.8rem;
}
.parserImgHeading {
  font-weight: 700;
}

.second-section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.card {
  background: #f9fafb;
  /* border: 1px solid #e0e0e0; */
  border-radius: 8px;
  padding: 12px 16px;
  box-shadow: 0 4px 6px rgba(139, 151, 171, 0.1);
  text-align: center;
  width: 290px;
  transition: transform 0.3s ease;
  cursor: pointer;
}
.card:hover {
  transform: scale(1.05); /* Zoom effect on hover */
  
}

.card h3 {
  margin-bottom: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #101828;
  margin-top: 10px;
}

.card p {
  font-size: 0.9rem;
  color: #475467;
}
