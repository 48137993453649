.maya-hero-section {
  background: 
    url("../../assets/mayaPage/bg-image.svg") no-repeat center center / cover, 
    linear-gradient(45deg, #121c26, #206dc5);
  padding: 130px 80px;
  min-height: 100%;
}
@media (max-width: 600px) {
  .maya-hero-section {
    padding: 100px 28px;
  }
}


