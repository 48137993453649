
  
 

.ant-carousel .slick-dots li button {
    margin-top: 10px;
      background:rgb(163, 163, 163); /* Change to your desired color */
      opacity: 1 !important;
      height: 4px;
      z-index: 1;

  }
  
  .ant-carousel .slick-dots li.slick-active button {
    background: #2888F6 ; /* Change to your desired color for the active dot */
    /* opacity: 0; */
    
  }

  @media (max-width: 1089px){
    .carousel-container-mobile-view{
        display: block;
    }
    .carousel-container{
        display: none;
    }
  }
  