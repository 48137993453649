.ant-card .ant-card-body {
  padding: 28px 24px;
}
/* #103662 #206DC5 */
.search-btn, .clear-btn, .apply-btn:hover {
    transition: all ease-in 0.2s;
}
.search-btn:hover, .apply-btn:hover {
  filter: brightness(1.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  border-color: #80bdff;
}
.clear-btn:hover {
  filter: brightness(1.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid #80bdff;
  color: #1962b6;
}
