/* HorizontalSection.css */
.horizontal-section {
    display: flex;
    overflow-x: auto;
    padding: 1rem;
    gap: 1.8rem;
    width: 100vw; /* Ensure the container takes the full viewport width */
    box-sizing: border-box; /* Include padding in the width */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  }
  
  .horizontal-section::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }
  
  .horizontal-section .btn3ImgContainer {
    flex-shrink: 0;
    text-align: center;
    width: 19vw; /* Adjust width as needed to fit 5 buttons in the viewport */
  }
  
  .horizontal-section .btn3Img {
    display: flex;
    justify-content: center;
  }
  