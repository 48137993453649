/* Keyframes to move the items horizontally */
@keyframes moveHorizontally {
  0% {
    transform: translateX(7%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Flex container will move the items horizontally */
.scroll-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 36px; /* Adjust gap between items */
  animation: moveHorizontally 30s linear infinite;
  animation-delay: 1s;
  will-change: transform;
}

/* Prevent items from shrinking */
.scroll-container > div {
  flex-shrink: 0;
  width: 305px; /* Adjust width of items */
  text-align: left;
}

